.home {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Add some content at the bottom of the video/page */
  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100%;

    h1 {
      color: $white-two;
      font-size: 50px;
      text-shadow: 2px 2px $black-one;

      @media only screen and (max-width: 440px) {
        font-size: 16px;
      }
    }

    p {
      color: $white-two;
      font-size: 30px;
      text-shadow: 2px 2px $black-one;

      @media only screen and (max-width: 440px) {
        font-size: 12px;
      }
    }
  }

  &-button {
    width: 400px;
    background-color: $primary-color;

    &:active,
    &:hover {
      background-color: $primary-color;
      filter: brightness(80%);
    }

    @media only screen and (max-width: 440px) {
      width: 200px;
      margin-top: 40px;
    }
  }
}
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
}
