.header {
  background-color: $black-two;
  display: -webkit-box;
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  z-index: 999;
  position: relative;

  @media only screen and (max-width: 900px) {
    display: flex;
    align-items: center;
  }

  &-links {
    align-self: center;

    a {
      text-transform: uppercase;
      padding: 0 20px;
      color: $white-two;
      font-size: 18px;

      &:hover,
      &:active {
        color: $primary-color;
      }
    }
  }

  &-logo {
    width: auto;

    img {
      height: 40px;
    }
  }
}
