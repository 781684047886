.footer {
  background-color: #171b21;
  padding: 1rem 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  h6 {
    color: white;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    text-align: center;
    margin: 0;

    @media only screen and (max-width: 440px) {
      font-size: 9px;
      letter-spacing: 0;
    }
  }
}
