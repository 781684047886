$primary-color: #0e98ba;
$secondary-color: #53dfd1;

$first-color: #9de3f6;
$second-color: #d3f4fb;

$white-one: #fff;
$white-two: #fafafa;
$white-three: #f8f8fa;

$black-one: #000;
$black-two: #161a21;
