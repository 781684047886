.project {
  @media only screen and (max-width: 440px) {
    padding: 0.5rem 0;
  }

  &-card {
    width: 360px;
    height: 660px;
    padding: 10px;
    margin: 10px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-box-pack: center;
    justify-content: center;

    @media only screen and (max-width: 990px) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
    }

    @media only screen and (max-width: 440px) {
      padding: 0.5rem;
    }

    .photo-container img {
      width: 100%;
      height: auto;
    }

    .video-container {
      -webkit-box-flex: 1;
      flex: 1;
      max-width: 100%;
      display: block;
      position: relative;
      padding-bottom: 56.25%;
      padding-top: 5rem;
      height: 0;
      overflow: hidden;

      @media only screen and (max-width: 440px) {
        margin-bottom: 1rem;
        padding-top: 1rem;
      }
    }

    .video-container iframe,
    .video-container object,
    .video-container embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-links {
    display: flex;
    justify-content: center;
    align-items: center;

    .fa {
      font-size: 60px;
      padding: 20px;

      @media only screen and (max-width: 990px) {
        font-size: 30px;
      }
    }

    p {
      text-transform: uppercase;
      font-size: 12px;
    }

    .fa,
    p {
      color: $black-two;

      &:active,
      &:hover {
        color: $primary-color;
        transition: ease 0.5s;
      }
    }

    .links {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.ant-card {
  &-meta-title {
    padding: 20px 0;
    text-transform: uppercase;
    text-align: center;
  }
}
