.about {
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  height: 100%;

  h2 {
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;

    @media only screen and (max-width: 440px) {
      font-size: 14px;
    }
  }

  &-content {
    display: flex;
    margin-top: 60px;

    @media only screen and (max-width: 900px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 40px;
      margin: 40px 0;

      margin: 0;
    }

    @media only screen and (max-width: 440px) {
      margin: 0;
    }

    &-image {
      padding-right: 60px;

      @media only screen and (max-width: 900px) {
        margin-bottom: 20px;
      }
      img {
        width: auto;
        max-height: 300px;
        border-radius: 150px;

        @media only screen and (max-width: 440px) {
          display: none;
        }
      }
    }

    p {
      font-size: 20px;
      line-height: 26px;

      @media only screen and (max-width: 440px) {
        font-size: 12px;
      }
    }
  }
}
