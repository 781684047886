.projects {
  display: flex;
  flex-direction: column;
  padding-bottom: 60px;

  h3 {
    margin: 1.5rem 0;
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;

    @media only screen and (max-width: 990px) {
      font-size: 16px;
    }
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
}
